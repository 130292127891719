.mainTitle{
    color: #848484;
    font-size: 13px;
    margin-bottom: 2px;
}
.mainContent{
    font-size: 15px;  
    border-bottom:1px ;
    border-bottom-style: solid;
    border-color: #848484;
    width: 90%;
}
.smallTitle{
    color: #848484;
    font-size: 12px;
    margin-bottom: 2px;
}
.smallContent{
    font-size: 13px;  
    border-bottom:1px ;
    border-bottom-style: solid;
    border-color: #848484;
    width: 90%;
}
.noExisten{
    font-size: 18px;
    font-weight: bold;
    padding-top:1%;
    padding-bottom: 2%;
}
.existen{
    padding-top: 1%;
    padding-bottom: 2%;
    font-size: 18px;
}

@media screen and (max-width: 768px) {
    .smallTitle{
        font-size: 10px;
    }
    .smallContent{
        font-size: 11px;
    }

}

